// DONE: RL20240422 - @adrian.julia.contractor - RED LEONARDO new branding
// NEW RED LEONARDO COLOR
@import 'comun/_variables.scss';

// NEW HEADER WITH LOGO
.main-header .leonardo {
    color: $red-leonardo;
}
.logo-isla {
    padding-top: 0 !important;
    h1, .epigraph {
        display:none !important;
    }
    img {
        max-height: 300px !important;
    }
}

// SHARE BAR
.share-bar {
    background-color: $red-leonardo !important;
}
